export const demoProducts = [
    {
        "id": "1",
        "name": "Product 1",
        "rating": 3.0,
        "description": "Description for product 1",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M','L'],
        "images": ["https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg",],
        "category": "trending",
        "price": 29.99
    },
    {
        "id": "2",
        "name": "Product 2",
        "rating": 2.5,
        "description": "Description for product 2",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg",],
        "category": "trending",
        "price": 39.99
    },
    {
        "id": "3",
        "name": "Product 3",
        "rating": 3.0,
        "description": "Description for product 3",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg", "https://m.media-amazon.com/images/I/81JkZBzVxRL._AC_UL640_FMwebp_QL65_.jpg",],
        "category": "trending",
        "price": 24.99
    },
    {
        "id": "4",
        "name": "Product 4",
        "rating": 4.9,
        "description": "Description for product 4",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/Rectangle 325.jpg", "/images/product2.jpg"],
        "category": "trending",
        "price": 49.99
    },
    {
        "id": "5",
        "name": "Product 5",
        "rating": 3.1,
        "description": "Description for product 5",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product2.jpg", "/images/product3.jpg"],
        "category": "trending",
        "price": 19.99
    },
    {
        "id": "6",
        "name": "Product 6",
        "rating": 2.8,
        "description": "Description for product 6",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product1.jpg", "/images/product1.jpg"],
        "category": "trending",
        "price": 34.99
    },
    {
        "id": "7",
        "name": "Product 7",
        "rating": 1.1,
        "description": "Description for product 7",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product1.jpg", "/images/product1.jpg"],
        "category": "trending",
        "price": 14.99
    },
    {
        "id": "8",
        "name": "Product 8",
        "rating": 4.4,
        "description": "Description for product 8",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product1.jpg", "/images/product1.jpg"],
        "category": "trending",
        "price": 44.99
    },
    {
        "id": "9",
        "name": "Product 9",
        "rating": 2.3,
        "description": "Description for product 9",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product1.jpg", "/images/product1.jpg"],
        "category": "trending",
        "price": 27.99
    },
    {
        "id": "10",
        "name": "Product 10",
        "rating": 2.2,
        "description": "Description for product 10",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product5.jpg", "/images/product1.jpg"],
        "category": "trending",
        "price": 22.99
    },
    {
        "id": "11",
        "name": "Product 11",
        "rating": 1.7,
        "description": "Description for product 11",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product5.jpg", "/images/product4.jpg"],
        "category": "new-arrivals",
        "price": 55.99
    },
    {
        "id": "12",
        "name": "Product 12",
        "rating": 4.7,
        "description": "Description for product 12",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product5.jpg", "/images/product2.jpg"],
        "category": "new-arrivals",
        "price": 45.99
    },
    {
        "id": "13",
        "name": "Product 13",
        "rating": 2.5,
        "description": "Description for product 13",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product4.jpg", "/images/product5.jpg"],
        "category": "new-arrivals",
        "price": 35.99
    },
    {
        "id": "14",
        "name": "Product 14",
        "rating": 3.7,
        "description": "Description for product 14",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product1.jpg", "/images/product3.jpg"],
        "category": "new-arrivals",
        "price": 40.99
    },
    {
        "id": "15",
        "name": "Product 15",
        "rating": 3.1,
        "description": "Description for product 15",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product2.jpg", "/images/product4.jpg"],
        "category": "new-arrivals",
        "price": 25.99
    },
    {
        "id": "16",
        "name": "Product 16",
        "rating": 3.8,
        "description": "Description for product 16",
        'colors': ['Red', 'Blue', 'Green'],
        'sizes': ['S', 'M', 'L'],
        "images": ["/images/product2.jpg", "/images/product4.jpg"],
        "category": "new-arrivals",
        "price": 30.99
    },
    {
        "id": "17",
        "name": "Product 17",
        "rating": 3.5,
        "description": "Description for product 17",
        "color": "Red",
        "size": "XL",
        "images": ["/images/product2.jpg", "/images/product1.jpg"],
        "category": "new-arrivals",
        "price": 32.99
    },
    {
        "id": "18",
        "name": "Product 18",
        "rating": 4.9,
        "description": "Description for product 18",
        "color": "Blue",
        "size": "M",
        "images": ["/images/product3.jpg", "/images/product4.jpg"],
        "category": "new-arrivals",
        "price": 55.99
    },
    {
        "id": "19",
        "name": "Product 19",
        "rating": 3.0,
        "description": "Description for product 19",
        "color": "Yellow",
        "size": "L",
        "images": ["/images/product2.jpg", "/images/product4.jpg"],
        "category": "new-arrivals",
        "price": 27.99
    },
    {
        "id": "20",
        "name": "Product 20",
        "rating": 3.7,
        "description": "Description for product 20",
        "color": "Red",
        "size": "S",
        "images": ["/images/product3.jpg", "/images/product5.jpg"],
        "category": "new-arrivals",
        "price": 42.99
    },
    {
        "id": "21",
        "name": "Product 21",
        "rating": 2.0,
        "description": "Description for product 21",
        "color": "Blue",
        "size": "M",
        "images": ["/images/product1.jpg", "/images/product3.jpg"],
        "category": "embroidered-abaya",
        "price": 29.99
    },
    {
        "id": "22",
        "name": "Product 22",
        "rating": 4.8,
        "description": "Description for product 22",
        "color": "Green",
        "size": "XL",
        "images": ["/images/product2.jpg", "/images/product3.jpg"],
        "category": "embroidered-abaya",
        "price": 59.99
    },
    {
        "id": "23",
        "name": "Product 23",
        "rating": 2.9,
        "description": "Description for product 23",
        "color": "Yellow",
        "size": "L",
        "images": ["/images/product5.jpg", "/images/product1.jpg"],
        "category": "embroidered-abaya",
        "price": 39.99
    },
    {
        "id": "24",
        "name": "Product 24",
        "rating": 3.2,
        "description": "Description for product 24",
        "color": "Red",
        "size": "L",
        "images": ["/images/product2.jpg", "/images/product4.jpg"],
        "category": "embroidered-abaya",
        "price": 34.99
    },
    {
        "id": "25",
        "name": "Product 25",
        "rating": 3.7,
        "description": "Description for product 25",
        "color": "Red",
        "size": "M",
        "images": ["/images/product3.jpg", "/images/product4.jpg"],
        "category": "embroidered-abaya",
        "price": 29.99
    },
    {
        "id": "26",
        "name": "Product 26",
        "rating": 2.2,
        "description": "Description for product 26",
        "color": "Yellow",
        "size": "L",
        "images": ["/images/product5.jpg", "/images/product1.jpg"],
        "category": "embroidered-abaya",
        "price": 27.99
    },
    {
        "id": "27",
        "name": "Product 27",
        "rating": 3.5,
        "description": "Description for product 27",
        "color": "Yellow",
        "size": "L",
        "images": ["/images/product4.jpg", "/images/product3.jpg"],
        "category": "embroidered-abaya",
        "price": 32.99
    },
    {
        "id": "28",
        "name": "Product 28",
        "rating": 1.9,
        "description": "Description for product 28",
        "color": "Blue",
        "size": "L",
        "images": ["/images/product4.jpg", "/images/product1.jpg"],
        "category": "embroidered-abaya",
        "price": 25.99
    },
    {
        "id": "29",
        "name": "Product 29",
        "rating": 4.1,
        "description": "Description for product 29",
        "color": "Green",
        "size": "S",
        "images": ["/images/product3.jpg", "/images/product5.jpg"],
        "category": "embroidered-abaya",
        "price": 31.99
    },
    {
        "id": "30",
        "name": "Product 30",
        "rating": 1.0,
        "description": "Description for product 30",
        "color": "Yellow",
        "size": "L",
        "images": ["/images/product1.jpg", "/images/product3.jpg"],
        "category": "embroidered-abaya",
        "price": 21.99
    }
]
